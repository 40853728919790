<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Documentos Avulsos Vendas</h5>
          </div>
          <div class="float-end">
            <button class="btn btn-system" @click="goBack()">
              Voltar
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormDocumentosAvulsosVendas></FormDocumentosAvulsosVendas>
        <button class="btn btn-system mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import FormDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/FormDocumentosAvulsosVendas.vue";
import RequestHelper from "@/services/RequestHelper";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateDocumentosAvulsosVendas",
  components: {FormDocumentosAvulsosVendas, LayoutPage},
  methods: {
    goBack(){
      history.back()
    },
    async sendForm() {
      let dataForm = {
        anexo: document.getElementById('anexo').dataset.value,
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,
        venda_id: this.$route.params.id


      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas', dataForm);
      if (response.data?.id) {
        location.href = '/documentos_avulsos_vendas/' + response.data.id + '/edit';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>