<template>

  <div class="content-wrapper">
    <div class="row">
      <div class="col-12">

        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-3 pt-4">
            <div style="border-radius: 1rem;"  class="card">
              <div class="card-header pt-5 pb-4">
                <div class="row">
                  <div class="col-12">
                    <img width="200" src="@/assets/logo.png">
                  </div>
                  <div class="col-12">
                  </div>
                </div>


              </div>
              <div class="card-body card-body-login">
                <div class="mb-5 mt-2 ">
                  <input type="text" class="form-control input-login" id="email" placeholder="Digite seu Usuário"
                  >
                </div>
                <div class="mb-4 mt-2 ">
                  <input type="password" class="form-control input-login" placeholder="Digite seu Senha"
                         id="password">
                </div>
                <div class="form-check mt-5">
                  <input class="form-check-input" type="checkbox" value="1" id="save">
                  <label class="form-check-label" for="flexCheckDefault">
                    Salvar a senha?
                  </label>
                </div>
              </div>
              <div class="card-footer pt-3 pb-4">
                <div class="row">
                  <div class="col-12 d-grid gap-2 ps-4 pe-4 pt-4">
                    <button @click="login" class="btn btn-lg btn-system btn-loading">
                      Entrar
                    </button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>


import axios from "axios";
import toastr from "toastr/build/toastr.min";
import registerForm from "@/views/auth/RegisterForm.vue";
import Middleware from "@/services/Middleware";
import Helpers from "@/services/Helpers";
import RequestHelper from "@/services/RequestHelper";


export default {
  name: "LoginForm",
  computed: {
    registerForm() {
      return registerForm
    }
  },
  data() {
    return {
      appName: process.env.VUE_APP_APPLICATION_NAME
    }
  }, methods: {
    login: function () {
      let middleware = new Middleware()
      middleware.beginLoading();
      let email = document.getElementById('email').value;
      let password = document.getElementById('password').value;
      let save = document.getElementById('save').value;

      let data = {
        email: email,
        password: password,
        save: save
      };
      axios.post(process.env.VUE_APP_API_HOST_NAME + '/api/auth/login', data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("HASH")}`,
          Accept: 'application/json',
          "Content-Type": 'application/json'
        }
      })
          .then(async function (response) {

            localStorage.setItem('HASH', response.data.data.token);


            let request = new RequestHelper();
            let user = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});

            if (user.data.type == 'super_admin') {
              location.href = '/business/index';
              return false;
            }


            let url = new Middleware().getRegisterLastRouteBeforeLogin()
            if (new Helpers().empty(url)) {
              url = '/painel'
            }

            location.href = url;

          }).catch(function (error) {

        middleware.endLoading();

        if (error.request.status == 401) {

          toastr.error("Login ou senha invalidos");

          return false;
        }
        console.log(error)
        toastr.error(error.response.data.message);
      });
    }
  },
  mounted() {

    let helper = new Helpers();
    if (!helper.empty(localStorage.getItem("HASH"))) {
      location.href = '/painel';
    }
  }
}
</script>

<style scoped>


.input-login {
  height: 50px;
}

.card-body-login, .row, .content-wrapper {
  overflow: hidden !important;
}

#image {
  position: fixed !important;
  width: 100%;
  right: 0px;
  z-index: -1;
  overflow: hidden;
}


@import "toastr/build/toastr.css";

</style>